import Image, { ImageProps, StaticImageData } from 'next/image';
import React from 'react';

interface LazyImageProps extends Omit<ImageProps, 'src'> {
  src?: string | StaticImageData;
}

const LazyImage = (props: LazyImageProps) => {
  const { src, width, height, alt, className } = props;
  return (
    <div>
      <Image
        {...props}
        alt={alt}
        src={src || ''}
        style={{ width, height }}
        className={`${className} opacity-0 transition-opacity duration-500`}
        onLoadingComplete={image => image.classList.remove('opacity-0')}
      />
    </div>
  );
};

export default LazyImage;
